import marketPlace from '@/modules/servicePages/marketplace/services/marketplace';
import { core } from '@/config/pluginInit';
export default {
  data () {
    return {
      addToCartLoading: false,
    };
  },
  methods: {

    async addToCart (product) {
      const quantity = this.$store.getters[ 'cart/getProductQuantity' ](product);
      this.addToCartLoading = true;
      await this.$store.dispatch('cart/addToCart', { ...product, quantity: quantity + 1 });
      this.addToCartLoading = false;
    },
    addToFavourite (product) {
      if (this.checkIfLogin()) {
        marketPlace.addProductWishlist({ product_id: product.id }).then(res => {
          this.$store.dispatch('cart/addToFavourite', product);
        })
          .catch(err => {
            console.log((err));
          });
      } else {
        core.showSnackbar('success', 'يجب تسجيل الدخول');
      }
    },
    checkFavourite (product) {
      return this.$store.getters[ 'cart/checkProductInFavourite' ](product);
    },
    deleteProductFavourite (product) {
      marketPlace.deleteProductWishList(product.id).then(res => {
        this.$store.dispatch('cart/deleteProductInFavourite', product);
      })
        .catch(err => {
          console.log((err));
        });
    }
  }
};
