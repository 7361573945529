<template>
    <div v-if="loadingData" class="d-flex justify-content-center">
      <spinner-loading text="Loading" />
    </div>
    <div v-else>
      <b-row v-if="products.length > 0 && !loadingData">
        <b-col
          xl="4"
          md="6"
          cols="12"
          v-for="(product, key) in products"
          :key="key"
        >
          <product-card
            :productItem="product"
            :index-of-item="key + 1"
            :inFav="true"
            @deleteFromFav="deleteFromFav"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="products.length === 0 && !loadingData"
        class="d-flex justify-content-center align-items-center flex-column mt-5"
      >
        <empty-cart class="mb-4"></empty-cart>
        <p class="text-black font-size-22">
          {{ $t("userProfile.emptyFavourite") }}
        </p>
        <b-button @click="$router.push('/')" class="w-25" variant="primary"
          >{{ $t("userProfile.goHomepage") }}
        </b-button>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/modules/servicePages/marketplace/components/productCard";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import emptyCart from "@/modules/servicePages/marketplace/components/cart/emptyCart";

export default {
  name: "favourite",
  components: { ProductCard, emptyCart },
  computed: {
    getProductFavourite() {
      return this.$store.getters["cart/getProductFavourite"];
    },
  },
  data() {
    return {
      loadingData: false,
      products: [],
    };
  },
  methods: {
    addInPayment(id) {
      if (this.checkIfLogin()) {
        this.$router.push({ name: "payment-actions", params: { id: id } });
      } else {
        this.$router.push({ name: "login" });
      }
    },
    getProductsWishList() {
      this.loadingData = true;
      marketPlace.getProductWishlist().then((res) => {
        this.products = res.data.data;
        this.$store.dispatch("cart/setFavourite", res.data.data);
        this.loadingData = false;
      });
    },
    deleteFromFav(id) {
      const findIndex = this.products.findIndex((product) => product.id === id);
      this.products.splice(findIndex, 1);
    },
  },
  created() {
    this.getProductsWishList();
  },
};
</script>

<style>
</style>
