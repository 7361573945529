<template>
  <div class="empty-cart d-flex align-items-center justify-content-center">
      <img :src="require('@/assets/images/ibbil/cart/empty-cart.svg')">
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'emptyCart',
  mounted () {
    core.index()
  }
}
</script>

<style>

</style>
