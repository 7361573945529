<template>
  <div
    class="prodCard-item overflow-hidden"
    :class="[
      {
        'margin-left-0': indexOfItem % 3 === 0,
        'margin-right-0': indexOfItem % 3 === 1,
      },
    ]"
  >
    <router-link
      :to="`/stores/${productItem.store_id}/products/${productItem.id}`"
    >
      <p v-if="inFav" class="product-name-in-fav">
        {{ productItem.store.name }}
      </p>
      <div
        class="prodCard-image"
        :style="{
          'background-image': 'url(' + productItem.featured_image + ')',
        }"
      ></div>
      <div class="d-flex flex-column align-items-start hold-content pb-0">
        <p class="product-name">
          {{
            productItem.title.length >= 25
              ? productItem.title.slice(0, 25) + "...."
              : productItem.title
          }}
        </p>
        <p class="product-description">
          {{
            productItem.description.length >= 25
              ? productItem.description.slice(0, 25) + "...."
              : productItem.description
          }}
        </p>
      </div>
    </router-link>
    <div class="d-flex justify-content-between align-items-center hold-content">
      <div
        class="product-price-info d-flex justify-content-center align-items-center"
      >
        <p class="product-price">
          {{ productItem.price
          }}<span class="product-currency"> {{ $t("main.sar") }}</span>
          <!-- <span class="d-block font-size-13"
              >{{ $t("main.per") }} {{ productItem.weight }}
              {{ productItem.weight_unit }}</span
            > -->
        </p>
      </div>
      <div class="d-flex">
        <b-button
          v-if="!checkFavourite(productItem)"
          @click.prevent="
            addToFavourite({
              ...productItem,
              catId: $route.params.id,
              storeSlogan: $route.params.slogan,
            })
          "
          class="product-card-heart-button"
        >
          <i class="las la-heart"></i>
        </b-button>
        <b-button
          v-else
          @click.prevent="deleteProductFavourite(productItem)"
          class="product-in-favourite-button"
        >
          <i class="las la-heart"></i>
        </b-button>
        <add-cart-icon :quantity="1" :product="productItem"></add-cart-icon>
      </div>
    </div>
  </div>
</template>

<script>
// import { core } from '@/config/pluginInit'
import productFunctions from "@/mixins/productFunctions";
import addCartIcon from "./profile/addCartIcon.vue";

export default {
  components: { addCartIcon },
  props: {
    inFav: {
      default: false,
    },
    indexOfItem: {
      type: Number,
    },
    productItem: {
      dafault: {},
    },
  },
  mixins: [productFunctions],
  data() {
    return {
      defaultImage: require("@/assets/images/ibbil/ibbil-cover.jpg"),
      title: "جلد طبيعى جلد خروف",
      description: "عبارة عن و صف التصنيع",
      price: "550",
      offer: "120.54",
      unit: "ر.س",
      rating: 5,
      discount: 16,
    };
  },
  computed: {},
  mounted() {
    // core.index()
  },
};
</script>

<style lang="scss" scoped>
.prodCard-item {
  background-color: #ffffff;
  box-shadow: 0 9px 20px #0000001a;
  border-radius: 2px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 1400px) and (min-width: 1200px) {
    width: 275px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.margin-left-0 {
  margin-left: 0;
}

.margin-right-0 {
  margin-right: 0;
}

.prodCard-image {
  width: 100%;
  height: 283px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 14px rgba(61, 61, 61, 0.13));
  @media (max-width: 1400px) and (min-width: 1200px) {
    height: 288px;
  }
}

.prodCard-image img {
  max-height: 100%;
}
.hold-content {
  padding: 1rem;
}
.product-name {
  color: #343a40;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 0.4rem !important;
}
.product-description {
  color: #666666;
  font-weight: 500;
  font-size: 0.9rem;
  background: transparent !important;
  padding: 0 !important;
  margin-bottom: 0.4rem !important;
}
.product-card-button {
  border-radius: 4px !important;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 1.5rem !important;
    margin: 0;
  }
}
.product-price {
  font-weight: 700;
  font-size: 1.2rem;
  color: #00443d;
  margin: 0 !important;
}
.product-currency {
  font-weight: 500;
  font-size: 1rem;
  color: #00443d;
}
.product-card-heart-button {
  border: none !important;
  background-color: #848484 !important;
  border-radius: 4px !important;
  box-shadow: 0 9px 20px #0000001a !important;
  width: 50px;
  height: 50px;
  margin: 0 0.8rem !important;
}

.product-card-heart-button i {
  color: #ffffff;
  font-size: 1.7rem;
  margin-left: 0 !important;
}

.product-in-favourite-button {
  border: 1px solid #eee !important;
  background-color: rgba(237, 237, 237, 0.78) !important;
  border-radius: 4px !important;
  // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12) !important;
  width: 50px;
  height: 50px;
  margin: 0 0.8rem !important;
}

.product-in-favourite-button i {
  color: #d62020;
  font-size: 1.7rem;
  margin-left: 0 !important;
}

.discount-product {
  background: #d62020;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.product-price-info .price-offer {
  margin-left: 12px;
  color: #ec5d61;
  text-decoration: line-through #ec5d61;
}
.product-name-in-fav {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 68, 61, 0.74);
  border-radius: 0 0 0 2px;
  padding: 0.5rem 1rem;
  color: #fff;
  z-index: 2;
  &:lang(en) {
    right: auto;
    left: 0;
  }
}
</style>
