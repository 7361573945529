var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prodCard-item overflow-hidden",class:[
    {
      'margin-left-0': _vm.indexOfItem % 3 === 0,
      'margin-right-0': _vm.indexOfItem % 3 === 1,
    },
  ]},[_c('router-link',{attrs:{"to":`/stores/${_vm.productItem.store_id}/products/${_vm.productItem.id}`}},[(_vm.inFav)?_c('p',{staticClass:"product-name-in-fav"},[_vm._v(" "+_vm._s(_vm.productItem.store.name)+" ")]):_vm._e(),_c('div',{staticClass:"prodCard-image",style:({
        'background-image': 'url(' + _vm.productItem.featured_image + ')',
      })}),_c('div',{staticClass:"d-flex flex-column align-items-start hold-content pb-0"},[_c('p',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.productItem.title.length >= 25 ? _vm.productItem.title.slice(0, 25) + "...." : _vm.productItem.title)+" ")]),_c('p',{staticClass:"product-description"},[_vm._v(" "+_vm._s(_vm.productItem.description.length >= 25 ? _vm.productItem.description.slice(0, 25) + "...." : _vm.productItem.description)+" ")])])]),_c('div',{staticClass:"d-flex justify-content-between align-items-center hold-content"},[_c('div',{staticClass:"product-price-info d-flex justify-content-center align-items-center"},[_c('p',{staticClass:"product-price"},[_vm._v(" "+_vm._s(_vm.productItem.price)),_c('span',{staticClass:"product-currency"},[_vm._v(" "+_vm._s(_vm.$t("main.sar")))])])]),_c('div',{staticClass:"d-flex"},[(!_vm.checkFavourite(_vm.productItem))?_c('b-button',{staticClass:"product-card-heart-button",on:{"click":function($event){$event.preventDefault();return _vm.addToFavourite({
            ..._vm.productItem,
            catId: _vm.$route.params.id,
            storeSlogan: _vm.$route.params.slogan,
          })}}},[_c('i',{staticClass:"las la-heart"})]):_c('b-button',{staticClass:"product-in-favourite-button",on:{"click":function($event){$event.preventDefault();return _vm.deleteProductFavourite(_vm.productItem)}}},[_c('i',{staticClass:"las la-heart"})]),_c('add-cart-icon',{attrs:{"quantity":1,"product":_vm.productItem}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }